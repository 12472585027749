import gsap from 'gsap';
import Sniffer from 'sniffer';
import imagesLoaded from 'imagesloaded';

export default class Filter {
  constructor() {
    this.page = document.querySelector('.a-page');
    this.filter = document.getElementById('filter');
    this.links = document.querySelectorAll('#filter li a');
    this.close = document.querySelector('#filter .p-close');
    this.dropdown = document.querySelector('.s-header-filter');

    this.visible = false;
  }

  state = () => {
    [...this.links].forEach((i) => {
      i.classList.remove('is-active');
    });
    [...this.links].forEach((i) => {
      if (i.href === location.href) {
        i.classList.add('is-active');
      }
    });
  }

  render = () => {
    this.py = this.page.getBoundingClientRect().top;
    this.st = this.collection.getBoundingClientRect().top;

    this.sh = this.collection.clientHeight;
    this.fh = this.filter.clientHeight;

    this.ot = this.collection.getBoundingClientRect().top - window.innerWidth * 0.09375;
    this.ob = this.sh - this.fh;

    if (this.ot > 0) {
      this.filter.style.transform = `translate3d(0, ${this.st}px, 0)`;
    }

    if (this.ot < 0) {
      this.filter.style.transform = `translate3d(0, ${this.st - this.py - this.sa}px, 0)`;
    }

    if (this.ot < -this.ob) {
      this.filter.style.transform = `translate3d(0, ${this.st + this.ob}px, 0)`;
    }
  }

  set = () => {
    this.filter.style.pointerEvents = 'all';
    this.filter.style.visibility = 'visible';

    this.sa = this.collection.getBoundingClientRect().top - window.innerWidth * 0.09375;
  }

  toggle = () => {
    this.filter.style.pointerEvents = 'all';
    this.filter.style.visibility = 'visible';

    gsap.killTweensOf(this.filter);

    if (this.visible === false) {
      this.visible = true;

      gsap.fromTo(this.links, {
        y: -window.innerWidth * 0.05, opacity: 0,
      }, {
        y: 0, opacity: 1, stagger: -0.1, duration: 1, ease: 'power3.out'
      });

      gsap.fromTo(this.filter, {
        y: -this.filter.clientHeight
      }, {
        y: 0, duration: 1, ease: 'power3.out'
      });

    } else {
      this.visible = false;
      
      gsap.fromTo(this.links, {
        opacity: 1,
      }, {
        opacity: 0, duration: 1,
      });

      gsap.to(this.filter, {
        y: -this.filter.clientHeight, duration: 1, ease: 'power3.out'
      });
    }
  }

  create = () => {
    gsap.ticker.add(this.render);
  }

  destroy = () => {
    this.collection === null;
    gsap.ticker.remove(this.render);

    if (Sniffer.isPhone) {
      [...this.links].forEach((i) => {
        i.removeEventListener('click', this.toggle);
      });

      if (this.dropdown) {
        this.close.removeEventListener('click', this.toggle);
        this.dropdown.removeEventListener('click', this.toggle);
      }
    }
  }

  events = () => {
    if (Sniffer.isPhone) {
      [...this.links].forEach((i) => {
        i.addEventListener('click', this.toggle);
      });

      if (this.dropdown) {
        this.close.addEventListener('click', this.toggle);
        this.dropdown.addEventListener('click', this.toggle);
      }
    }
  }

  init() {
    this.collection = document.querySelector('.s-collection');
    if (this.collection) {
      this.state();
      this.events();
      if (Sniffer.isPhone) return;
      imagesLoaded(this.page, () => {
        this.set();
        this.create();
      });
    } else {
      this.filter.style.visibility = 'hidden';
      this.filter.style.pointerEvents = 'none';
    }
  }
}
