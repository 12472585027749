// gsap
import gsap from 'gsap';
import Sniffer from 'sniffer';
import CustomEase from 'gsap/CustomEase';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';

// config
import config from '../../utils/config';

// register
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(DrawSVGPlugin);

// loader
class Loader {
  constructor() {
    this.menu = document.getElementById('menu');
    this.header = document.querySelector('header');
    this.burger = document.getElementById('burger');

    this.loader = {
      el: document.getElementById('loader'),
      logo: document.querySelector('#loader .i-logo'),
      paths: document.querySelectorAll('#loader .i-logo *'),
    }

    CustomEase.create('exo', config.ease);
  }

  set = () => {
    gsap.set([this.burger, this.menu], {
      y: -config.wh,
      visibility: 'inherit',
    });

    gsap.set(this.loader.el, {
      clip: `rect(0px ${config.ww}px ${config.wh}px 0px)`,
    });

    gsap.set(this.loader.paths, {
      drawSVG: `0% 0%`,
    });

    gsap.set(this.loader.logo, {
      opacity: 1,
    });
  }

  default = () => {
    gsap.fromTo(this.loader.paths, {
      drawSVG: `0% 0%`,
    }, {
      delay: 0.5,
      duration: 1,
      stagger: 0.02,
      drawSVG: `0% 100%`,
      ease: 'power3.out',
    });

    gsap.to(this.loader.el, {
      delay: 2,
      ease: 'exo',
      duration: 1,
      clip: `rect(${config.wh}px ${config.ww}px ${config.wh}px 0px)`,
    });

    gsap.fromTo(this.header, {
      y: -config.wh / 2,
    }, {
      y: 0,
      delay: 2,
      ease: 'exo',
      duration: 1,
      clearProps: 'all',
    });

    gsap.to([this.burger, this.menu], {
      y: 0,
      delay: 2,
      duration: 1,
      ease: 'expo',
      onComplete: () => {
        this.done();
      }
    });
  }

  done = () => {
    document.body.style.overflow = '';
    document.body.classList.remove('is-loading');
  }

  init() {    
    if (!Sniffer.isPhone) {
      if (document.querySelector('.s-hero')) return;
    }

    this.set();
    this.default();
  }
}

export default Loader;
