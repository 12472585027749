let config = {
  body: document.body,

  ww: window.innerWidth,
  wh: window.innerHeight,

  nav: document.getElementById('nav'),
  menu: document.getElementById('menu'),
  page: document.querySelector('.a-page'),
  cursor: document.getElementById('cursor'),

  ease: 'M0,0 C0,0 0.05506,0.00231 0.0875,0.00809 0.11176,0.01241 0.1283,0.01701 0.15051,0.0268 0.17504,0.03762 0.19222,0.04708 0.21316,0.0635 0.23519,0.08078 0.2517,0.095 0.26676,0.11797 0.29108,0.15506 0.30651,0.18433 0.32142,0.2286 0.37985,0.40217 0.40411,0.5212 0.46004,0.69208 0.46738,0.71452 0.47734,0.72761 0.49122,0.74743 0.51762,0.78511 0.5345,0.80999 0.56551,0.84264 0.59155,0.87006 0.61189,0.88712 0.64337,0.90762 0.67606,0.9289 0.7006,0.94111 0.7376,0.95466 0.78311,0.97134 0.81451,0.97967 0.86275,0.988 0.91406,0.99687 1,1 1,1',
}

export default config