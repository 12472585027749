import Sniffer from 'sniffer';

import Agents from '../components/agents/agents';
import Background from '../components/background/background';
import Banner from '../components/banner/banner';
import Carousel from '../components/carousel/carousel';
import Categories from '../components/categories/categories';
import Collection from '../components/collection/collection';
import Call from '../components/call/call';
import Faq from '../components/faq/faq';
import Footer from '../components/footer/footer';
import Header from '../components/header/header';
import Help from '../components/help/help';
import Hero from '../components/hero/hero';
import Image from '../components/image/image';
import Images from '../components/images/images';
import Instagram from '../components/instagram/instagram';
import Lookbook from '../components/lookbook/lookbook';
import Menu from '../components/menu/menu';
import Newsletter from '../components/newsletter/newsletter';
import Product from '../components/product/product';
import Scroll from './scroll/scroll';
import Text from '../components/text/text';
import Values from '../components/values/values';
import Video from '../components/video/video';

export default class Components {
  constructor() {
    this.agents = document.querySelector('.s-agents');
    this.banner = document.querySelector('.s-banner');
    this.call = document.querySelector('.s-call');
    this.carousel = document.querySelector('.s-carousel');
    this.categories = document.querySelector('.s-categories');
    this.collection = document.querySelector('.s-collection');
    this.faq = document.querySelector('.s-faq');
    this.footer = document.querySelector('.s-footer');
    this.header = document.querySelector('[class^="s-header-"]');
    this.help = document.querySelector('.s-help');
    this.hero = document.querySelector('.s-hero');
    this.image = document.querySelector('.s-image');
    this.images = document.querySelector('.s-images');
    this.instagram = document.querySelector('.s-instagram');
    this.lookbook = document.querySelector('.js-lookbook');
    this.menu = document.querySelector('#menu');
    this.newsletter = document.querySelector('.s-newsletter');
    this.product = document.querySelector('.s-product');
    this.text = document.querySelector('.s-text');
    this.values = document.querySelector('.s-values');
    this.video = document.querySelector('.s-video');
  }

  destroy = () => {
    if (!Sniffer.isDevice) {
      this.scroll.destroy();
    }

    this.background.destroy();
    this.menu.destroy();

    if (this.call) { this.call.destroy(); }
    if (this.carousel) { this.carousel.destroy(); }
    if (this.categories) { this.categories.destroy(); }
    if (this.collection) { this.collection.destroy(); }
    if (this.footer) { this.footer.destroy(); }
    if (this.header) { this.header.destroy(); }
    if (this.hero) { this.hero.destroy(); }
    if (this.image) { this.image.destroy(); }
    if (this.lookbook) { this.lookbook.destroy(); }
    if (this.values) { this.values.destroy(); }
    if (this.video) { this.video.destroy(); }
  }

  init() {
    this.background = new Background();
    this.background.init();

    if (!Sniffer.isDevice) {
      this.scroll = new Scroll();
      this.scroll.init();
    }

    if (this.agents) {
      this.agents = new Agents();
      this.agents.init();
    }

    if (this.banner) {
      this.banner = new Banner();
      this.banner.init();
    }

    if (this.call) {
      this.call = new Call();
      this.call.init();
    }

    if (this.carousel) {
      this.carousel = new Carousel();
      this.carousel.init();
    }

    if (this.categories) {
      this.categories = new Categories();
      this.categories.init();
    }

    if (this.collection) {
      this.collection = new Collection();
      this.collection.init();
    }

    if (this.faq) {
      this.faq = new Faq();
      this.faq.init();
    }

    if (this.footer) {
      this.footer = new Footer();
      this.footer.init();
    }

    if (this.header) {
      this.header = new Header();
      this.header.init();
    }

    if (this.help) {
      this.help = new Help();
      this.help.init();
    }

    if (this.hero) {
      this.hero = new Hero();
      this.hero.init();
    }

    if (this.image) {
      this.image = new Image();
      this.image.init();
    }

    if (this.images) {
      this.images = new Images();
      this.images.init();
    }

    if (this.instagram) {
      this.instagram = new Instagram();
      this.instagram.init();
    }

    if (this.lookbook) {
      this.lookbook = new Lookbook();
      this.lookbook.init();
    }

    if (this.menu) {
      this.menu = new Menu();
      this.menu.init();
    }

    if (this.newsletter) {
      this.newsletter = new Newsletter();
      this.newsletter.init();
    }

    if (this.product) {
      this.product = new Product();
      this.product.init();
    }

    if (this.text) {
      this.text = new Text();
      this.text.init();
    }

    if (this.values) {
      this.values = new Values();
      this.values.init();
    }

    if (this.video) {
      this.video = new Video();
      this.video.init();
    }
  }
}
