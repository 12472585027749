import gsap from 'gsap';
import config from '../../utils/config';

export default class Images {
  constructor() {
    this.page = document.querySelector('.a-page');
    this.section = document.querySelectorAll('.s-images');
    this.images = document.querySelectorAll('.s-images-figure');

    this.total = 0;
  }

  set = (i) => {
    this.font = {
      p: i.querySelectorAll('p'),
    }

    if (this.font.p) {
      [...this.font.p].forEach((i) => {
        i.classList.add('f-pr');
      });
    }

    gsap.set(this.images, { autoAlpha: 0, y: config.ww * 0.125, });
  }

  transform = (i, p) => {
    if (i.classList.contains('figure-small')) {
      i.querySelector('.s-images-wrap').style.transform = `matrix(1, 0, 0, 1, 0, ${p})`;
    }
  }

  inview = (i) => {
    const b = i.getBoundingClientRect();

    const d = (config.wh + this.py) - (b.top + this.py);
    const p = d / ((config.wh + b.height) / 100);

    if (p > 0 && p < 100) {
      this.transform(i, p);
    }

    if (this.total === this.images.length) return;

    if (p > 10 && i.inview === undefined) {
      this.total += 1;
      i.inview = true;
      gsap.to(i, { autoAlpha: 1, y: 0, duration: 3, ease: 'power3.out' });
    }
  }

  render = () => {
    this.py = Math.abs(this.page.getBoundingClientRect().top);

    [...this.images].forEach((i) => {
      this.inview(i);
    });
  }

  resize = () => {
    config.wh = window.innerHeight;
  }

  create = () => {
    gsap.ticker.add(this.render);
    window.addEventListener('resize', this.resize, { passive: true });
  }

  destroy = () => {
    window.removeEventListener('resize', this.resize);
  }

  init() {
    [...this.section].forEach((i) => {
      this.set(i);
    });
    this.create();
  }
}
