import gsap from 'gsap';
import Sniffer from 'sniffer';
import SplitText from 'gsap/SplitText';

export default class Effects {
  constructor() {
    this.effect = {
      block: document.querySelectorAll('[data-effect="block"]'),
      heading: document.querySelectorAll('[data-effect="heading"]'),
    }

    this.total = 0;
  }

  setBlock = (i) => {
    let arr = [];

    let st = i.querySelector('.f-st');
    let hm = i.querySelector('.f-hm');
    let pr = i.querySelector('.f-pr');
    let bp = i.querySelector('.f-bp');

    if (st) { st = new SplitText(st, { type: 'lines' }); arr.push(st.lines); };
    if (hm) { hm = new SplitText(hm, { type: 'lines' }); arr.push(hm.lines); };
    if (pr) { pr = new SplitText(pr, { type: 'lines' }); arr.push(pr.lines); };
    if (bp) { arr.push(bp); };

    gsap.set([arr], { opacity: 0, rotation: 5, transformOrigin: '0 0', y: window.innerWidth * 0.05, });
  }

  setHeading = (i) => {
    let st = i.querySelector('.f-st');
    let hl = i.querySelector('.f-hl');

    if (st) { gsap.set(st, { opacity: 0, }); }
    if (hl) {
      hl = new SplitText(hl, { type: 'lines,words,chars' });

      [...hl.lines].forEach((i) => {
        i.classList.add('f-hl-line');
      });

      [...hl.words].forEach((i) => {
        gsap.set(i, { opacity: 0, y: -window.innerWidth * 0.104, className: '+=f-hl-word', });
      });

      [...hl.chars].forEach((i) => {
        i.classList.add('f-hl-char');
      });
    };
  }

  render = () => {
    // blocks
    [...this.effect.block].forEach((i) => {
      const b = i.getBoundingClientRect();

      if (b.top < window.innerHeight / 1.5) {
        if (i.inview === undefined) {
          i.inview = true;

          let arr = [];

          const st = i.querySelectorAll('.f-st div');
          const hm = i.querySelectorAll('.f-hm div');
          const pr = i.querySelectorAll('.f-pr div');
          const bp = i.querySelector('.f-bp');

          if (st.length > 0) { arr.push(st); }
          if (hm.length > 0) { arr.push(hm); }
          if (pr.length > 0) { arr.push(pr); }
          if (bp) { arr.push(bp); }

          gsap.to([arr], { y: 0, opacity: 1, rotation: 0, duration: 2, stagger: 0.05, force3D: true, ease: 'expo.out', });

          this.total += 1;
        }
      }
    });

    // headings
    [...this.effect.heading].forEach((i) => {
      const b = i.getBoundingClientRect();

      if (b.top < window.innerHeight / 1.5) {
        if (i.inview === undefined) {
          i.inview = true;

          const st = i.querySelector('.f-st');
          const hl = i.querySelectorAll('.f-hl-line');
          const hw = i.querySelectorAll('.f-hl-word');

          gsap.to(st, { opacity: 1, delay: 1, });
          gsap.to(hw, { opacity: 1, y: 0, delay: 0.5, stagger: -0.02, duration: 1.25, ease: 'power3.out' });

          [...hl].forEach((i) => {
            let arr = [];

            const g = i.querySelectorAll('.f-hl-word');
            const w = g.length - 1;
            const l = g[w].querySelectorAll('.f-hl-char');

            if (l[l.length - 1]) { arr.push(l[l.length - 1]); }
            if (l[l.length - 2]) { arr.push(l[l.length - 2]); }
            if (l[l.length - 3]) { arr.push(l[l.length - 3]); }

            gsap.fromTo(arr,
              { y: -window.innerWidth * 0.02604, opacity: 0, },
              {
                opacity: 1, y: 0, stagger: -0.2, delay: 0.5, duration: 1.25, ease: 'power3.out',
              });
          });

          this.total += 1;
        }
      }
    });

    // destroy
    if (this.total === this.effect.block.length + this.effect.heading.length) {
      this.destroy();
    }
  }

  create = () => {
    gsap.ticker.add(this.render);
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
  }

  preload() {
    setTimeout(() => {
      this.init();
    }, 1500);
  }

  init() {
    if (Sniffer.isDevice) return;

    [...this.effect.block].forEach((i) => {
      this.setBlock(i);
    });

    [...this.effect.heading].forEach((i) => {
      this.setHeading(i);
    });

    this.create();
  }
}
