import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import config from '../../utils/config';

gsap.registerPlugin(CustomEase);

export default class Product {
  constructor() {
    this.gallery = {
      img: document.querySelectorAll('.s-product-gallery img'),
    }

    this.total = 0;

    CustomEase.create('exo', config.ease);
  }

  set = () => {
    gsap.set(this.gallery.img, { y: window.innerWidth * 0.125, opacity: 0, });
  }

  render = () => {
    [...this.gallery.img].forEach((i) => {
      const b = i.getBoundingClientRect();

      if (b.top < window.innerHeight) {
        if (i.inview === undefined) {
          i.inview = true;
          this.total += 1;
          gsap.to(i, { y: 0, opacity: 1, duration: 1.5, ease: 'exo' });
        }
      }
    });

    if (this.total === this.gallery.img.length) {
      this.destroy();
    }
  }

  create = () => {
    gsap.ticker.add(this.render);
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
  }

  init() {
    this.set();
    this.create();
  }
}
