import gsap from 'gsap';

export default class Cursor {
  constructor() {
    this.cursor = document.getElementById('cursor');
    this.icon = document.querySelector('#cursor div');

    this.tX = 0;
    this.tY = 0;

    this.pX = 0;
    this.pY = 0;
  }

  render = () => {
    this.pX += ((this.tX - this.pX - this.icon.clientWidth / 2) * 0.07);
    this.pY += ((this.tY - this.pY - this.icon.clientHeight / 2) * 0.07);

    this.cursor.style.transform = `translate3d(${this.pX}px, ${this.pY}px, 0)`;
  }

  enter = (i) => {
    const el = i.target;

    if (el.getAttribute('data-cursor') === 'link') {
      this.icon.classList.add('c-link');
    }

    if (el.getAttribute('data-cursor') === 'more') {
      this.icon.classList.add('c-more');
    }

    if (el.getAttribute('data-cursor') === 'zoom') {
      this.icon.classList.add('c-zoom');
    }

    gsap.to(this.icon, {
      opacity: 1,
      duration: 0.5,
    });
  }

  leave = () => {
    this.icon.setAttribute('class', '');

    gsap.to(this.icon, { opacity: 0, duration: 0.5, });
  }

  events = () => {
    this.targets = document.querySelectorAll('[data-cursor]');

    [...this.targets].forEach((i) => {
      i.addEventListener('mouseenter', i => {
        this.enter(i);
      });

      i.addEventListener('click', this.leave);
      i.addEventListener('mouseleave', this.leave);
    });

    this.leave();
  }

  init() {
    window.addEventListener('mousemove', (e) => {
      this.tX = e.clientX;
      this.tY = e.clientY;
    });

    this.events();

    gsap.ticker.add(this.render);
  }
}
