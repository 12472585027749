import gsap from 'gsap';

export default class Background {
  constructor() {
    this.background = document.getElementById('background');
    this.color = document.querySelectorAll('[data-background]');
  }

  render = () => {
    [...this.color].forEach((el) => {
      const bounding = el.getBoundingClientRect();
      if (bounding.top < window.innerHeight && bounding.bottom > 0) {
        this.background.style.backgroundColor = el.dataset.background;
      }
    });
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
  }

  init() {
    gsap.ticker.add(this.render);
  }
}
