import gsap from 'gsap';
import Sniffer from 'sniffer';

export default class Collection {
  constructor() {
    this.product = document.querySelectorAll('.s-collection-product');

    this.wh = window.innerHeight;

    this.total = 0;
  }

  set = () => {
    [...this.product].forEach((i) => {
      gsap.set(i, { opacity: 0, y: window.innerWidth * 0.0625, });
    });
  }

  render = () => {
    [...this.product].forEach((el) => {
      const bt = el.getBoundingClientRect().top;

      if (bt < this.wh) {
        if (el.inview === undefined) {

          if (Sniffer.isMobile) {
            if (this.total === 2) {
              this.total = 1;
            } else {
              this.total++;
            }
          } else {
            if (this.total === 4) {
              this.total = 1;
            } else {
              this.total++;
            }
          }

          el.inview = true;

          const delay = 0.1 * this.total;
          const thumbnail = el.querySelector('.s-collection-thumbnail');

          if (Sniffer.isEdge) {
            const img = document.createElement('img');

            img.alt = thumbnail.dataset.alt;
            img.src = thumbnail.dataset.lazy;

            thumbnail.appendChild(img);
          } else {
            const img = new Image();

            img.alt = thumbnail.dataset.alt;
            img.src = thumbnail.dataset.lazy;

            img.decode().then(() => {
              thumbnail.appendChild(img);
            });
          }

          gsap.to(el, {
            y: 0, delay, opacity: 1, duration: 1.5, ease: 'expo.out', clearProps: 'all',
          });

          thumbnail.removeAttribute('data-alt');
          thumbnail.removeAttribute('data-lazy');
        }
      }
    });
  }

  enter = (e) => {
    const images = e.target.querySelectorAll('img');
    if (images.length === 1) {
      const thumbnail = e.target.querySelector('.s-collection-thumbnail');
      let img;
      if (Sniffer.isEdge) {
        img = document.createElement('img');
        img.src = thumbnail.dataset.hover;
        thumbnail.appendChild(img);
      } else {
        img = new Image();
        img.src = thumbnail.dataset.hover;
        img.decode().then(() => {
          thumbnail.appendChild(img);
        });
      }
      gsap.set(img, { opacity: 1, });
    } else {
      gsap.set(images[1], { opacity: 1, });
    }
  }

  leave = (e) => {
    const images = e.target.querySelectorAll('img');
    if (images.length === 2) {
      gsap.set(images[1], { opacity: 0, });
    }
  }

  resize = () => {
    this.wh = window.innerHeight;
  }

  ticker = () => {
    gsap.ticker.add(this.render);
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
    window.removeEventListener('resize', this.resize);
  }

  events = () => {
    window.addEventListener('resize', this.resize, { passive: true });

    if (Sniffer.isDevice) return;

    [...this.product].forEach((e) => {
      e.addEventListener('mouseenter', this.enter);
      e.addEventListener('mouseleave', this.leave);
    });
  }

  init() {
    this.set();
    this.events();
    this.ticker();
  }
}
