export default class HeaderImage {
  constructor() {
    this.section = document.querySelector('.s-header-image');
  }

  set = () => {
    this.font = {
      h2: this.section.querySelector('h2'),
    }

    this.font.h2.classList.add('f-hl');
  }

  init() {
    this.set();
  }
}
