import gsap from 'gsap';
import config from '../../utils/config';

export default class HeaderContact {
  constructor() {
    this.page = document.querySelector('.a-page');
    this.header = document.querySelector('.s-header-contact');

    this.figure = this.header.querySelector('.s-header-figure');
    this.graphic = this.header.querySelector('.s-header-graphic');

    this.delta = 0;
    this.rotate = 0;
    this.rotation = 0;

    this.intro = false;
  }

  clear = () => {
    this.lp = null;
    this.delta = 0;
  }

  velocity = () => {
    this.py = Math.abs(this.page.getBoundingClientRect().top);

    this.delay = 50;

    if (this.lp != null) {
      this.delta = this.py - this.lp;
    }

    this.lp = this.py;

    clearTimeout(this.timer);

    this.timer = setTimeout(this.clear, this.delay);

    if (this.rotate > 360) { this.rotate = 0; }

    this.rotate += 1 / 20;
  }

  transform = () => {
    if (this.rotation < -360) { this.rotation = 0; };
    if (this.rotation > 360) { this.rotation = 0; };

    this.rotation = this.rotation + this.delta / 10;

    this.figure.style.transform = `rotate(${this.rotate}deg)`;

    if (this.intro === true) return;

    this.graphic.style.transform = `rotate(${this.rotation}deg)`;
  }

  animate = () => {
    gsap.fromTo(this.graphic,
      { rotation: -45, },
      { rotation: 0, duration: 2.5, ease: 'power3.out', onComplete: () => { this.intro = false }, }
    );
  }

  render = () => {
    this.velocity();
    this.transform();
  }

  resize = () => {
    config.wh = window.innerHeight;
  }

  create = () => {
    gsap.ticker.add(this.render);

    window.addEventListener('resize', this.resize, { passive: true });
  }

  destroy = () => {
    gsap.ticker.remove(this.render);

    window.removeEventListener('resize', this.resize);
  }

  init() {
    this.create();
    // this.animate();
  }
}
