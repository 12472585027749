import gsap from 'gsap';

// instances
import { instances } from '../../utils/store';

export default class Cookies {
  constructor() {
    this.section = document.getElementById('cookies');

    this.close = this.section.querySelector('.f-bc');
    this.accept = this.section.querySelector('.f-bp');
  }

  init() {
    if (document.cookie.match(/^(.*;)?\s*enabled\s*=\s*[^;]+(.*)?$/)) {
      // console.log('cookies enabled');
      this.section.remove();
    } else {
      this.events();
      // console.log('cookies disabled');
      gsap.to(this.section, {
        delay: 3,
        opacity: 1,
      });
    }
  }

  events() {
    this.accept.addEventListener('click', this.enabled);
    this.close.addEventListener('click', this.disabled);
  }

  disabled = () => {
    // console.log('disable analytics');
    instances.cookies = false;
    window['ga-disable-UA-166878310-1'] = true;
    this.hide();
  }

  enabled = () => {
    // console.log('enable analytics');
    instances.cookies = true;
    document.cookie = 'enabled=yes';
    this.hide();
  }

  hide = () => {
    gsap.to(this.section, {
      opacity: 0,
      onComplete: () => {
        this.section.remove();
      }
    });
  }
}
