import gsap from 'gsap';
import Sniffer from 'sniffer';

export default class Banner {
  constructor() {
    this.page = document.querySelector('.a-page');
    this.section = document.querySelectorAll('.s-banner');
  }

  set = (i) => {
    this.font = {
      h3: i.querySelector('h3'),
      h2: i.querySelector('h2'),
      p: i.querySelector('p'),
    }

    if (this.font.h3) this.font.h3.classList.add('f-st');
    if (this.font.h2) this.font.h2.classList.add('f-hm');
    if (this.font.p) this.font.p.classList.add('f-pr');
  }

  transform = (i, p) => {
    const img = i.querySelectorAll('img');
    const src = i.querySelectorAll('.s-banner-src');

    src[0].style.transform = `matrix(1, 0, 0, 1, 0, ${p})`;

    if (Sniffer.isPhone) return;

    const s = p / 300;

    img[0].style.transform = `scale(${1.5 - s})`;
  }

  inview = (i) => {
    const py = Math.abs(this.page.getBoundingClientRect().top);
    const gb = i.getBoundingClientRect();

    const d = (window.innerHeight + py) - (gb.top + py);
    const p = d / ((window.innerHeight + gb.height) / 100);

    if (p > 0 && p < 100) {
      this.transform(i, p);
    }
  }

  render = () => {
    [...this.section].forEach((i) => {
      this.inview(i);
    });
  }

  create = () => {
    gsap.ticker.add(this.render);
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
  }

  init() {
    [...this.section].forEach((i) => {
      this.set(i);
    });

    this.create();
  }
}
