import gsap from 'gsap';
import Sniffer from 'sniffer';

export default class Categories {
  constructor() {
    this.section = document.querySelectorAll('.s-categories');
    this.item = document.querySelectorAll('.s-categories-item');
    this.link = document.querySelectorAll('.s-categories-link');
    this.image = document.querySelectorAll('.s-categories-image');
    this.current = 0;
  }

  setStyles = () => {
    this.ww = window.innerWidth;

    gsap.set(this.image[0], { width: this.ww / 20 * 2, height: this.ww * 0.141, });
    gsap.set(this.image[1], { width: this.ww / 20 * 3, height: this.ww * 0.212, });
    gsap.set(this.image[2], { width: this.ww / 20 * 4, height: this.ww * 0.284, });
  }

  getLayout = () => {
    this.layout = Math.floor(Math.random() * 6);
    if (this.current === this.layout) {
      this.getLayout();
    } else {
      this.current = this.layout;
      this.setLayout();
    }
  }

  setLayout = () => {
    this.ww = window.innerWidth;

    const i = this.image;
    const l = this.layout;

    gsap.killTweensOf(i);
    gsap.set(i, { yPercent: 50, opacity: 0, });

    if (l === 0) {
      gsap.set(i[0], { x: this.ww / 20 * 2, y: -this.ww * 0.2, });
      gsap.set(i[1], { x: this.ww / 20 * 4, y: -this.ww * 0.01, });
      gsap.set(i[2], { x: this.ww / 20 * 14, y: -this.ww * 0.25, });
    }

    if (l === 1) {
      gsap.set(i[0], { x: this.ww / 20 * 14, y: -this.ww * 0.2, });
      gsap.set(i[1], { x: this.ww / 20 * 16, y: -this.ww * 0.01, });
      gsap.set(i[2], { x: this.ww / 20 * 2, y: -this.ww * 0.25, });
    }

    if (l === 2) {
      gsap.set(i[0], { x: this.ww / 20 * 14, y: -this.ww * 0.2, });
      gsap.set(i[1], { x: this.ww / 20 * 16, y: -this.ww * 0.01, });
      gsap.set(i[2], { x: this.ww / 20 * 2, y: -this.ww * 0.25, });
    }

    if (l === 3) {
      gsap.set(i[0], { x: this.ww / 20 * 4, y: -this.ww * 0.2, });
      gsap.set(i[1], { x: this.ww / 20 * 1, y: -this.ww * 0.01, });
      gsap.set(i[2], { x: this.ww / 20 * 14, y: -this.ww * 0.25, });
    }

    if (l === 4) {
      gsap.set(i[1], { x: this.ww / 20 * 3, y: -this.ww * 0.01, });
      gsap.set(i[2], { x: this.ww / 20 * 14, y: -this.ww * 0.25, });
    }

    if (l === 5) {
      gsap.set(i[1], { x: this.ww / 20 * 14, y: -this.ww * 0.01, });
      gsap.set(i[2], { x: this.ww / 20 * 2, y: -this.ww * 0.25, });
    }

    if (l === 0 || l === 1 || l === 2 || l === 3) {
      gsap.to(i, { opacity: 1, duration: 2, yPercent: 0, stagger: 0.1, ease: 'expo.out', });
    }

    if (l === 4 || l === 5) {
      gsap.to([i[1], i[2]], { opacity: 1, duration: 2, yPercent: 0, stagger: 0.1, ease: 'expo.out', });
    }
  }

  getImages = (i) => {
    if (!i) {
      i = 0;
    }

    this.images = this.item[i].querySelectorAll('.s-categories-src div');

    [...this.images].forEach((el, i) => {
      this.image[i].querySelector('img').src = el.dataset.src;
    });
  }

  mouseEnter = (i) => {
    this.getLayout();
    this.getImages(i);
  }

  resize = () => {
    this.setStyles();
    this.setLayout();
  }

  destroy = () => {
    window.removeEventListener('resize', this.resize);
  }

  addEvents = () => {
    [...this.link].forEach((el, i) => {
      el.addEventListener('mouseenter', () => {
        this.mouseEnter(i);
      });
    });

    window.addEventListener('resize', this.resize, { passive: true });
  }

  init() {
    if (Sniffer.isPhone) return;

    this.addEvents();
    this.setStyles();
    this.getLayout();
    this.getImages();
  }
}
