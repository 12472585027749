import gsap from 'gsap';
import Sniffer from 'sniffer';

import CustomEase from 'gsap/CustomEase';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';

import config from '../utils/config';
import Highway from '@dogstudio/highway';

gsap.registerPlugin(CustomEase);
gsap.registerPlugin(DrawSVGPlugin);

class Default extends Highway.Transition {
  in({ from, to, done }) {
    from.remove();

    this.ww = window.innerWidth;
    this.wh = window.innerHeight;

    this.app = document.getElementById('app');
    this.header = document.querySelector('header');
    this.loader = document.getElementById('loader');
    const logo = document.querySelector('#loader svg');

    CustomEase.create('exo', config.ease);

    if (Sniffer.isDevice) {
      this.app.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }

    gsap.to(logo, {
      ease: 'exo',
      duration: 1,
      y: window.innerHeight / 4,
    });

    gsap.fromTo(this.header, {
      y: -this.wh / 2,
    }, {
      y: 0,
      ease: 'exo',
      duration: 1,
      clearProps: 'all',
    });

    gsap.to(this.loader, {
      ease: 'exo',
      duration: 1,
      clip: `rect(${this.wh}px ${this.ww}px ${this.wh}px 0px)`,
      onComplete: () => {
        document.body.style.overflow = '';
      },
    });

    done();
  }

  out({ from, done }) {
    this.ww = window.innerWidth;
    this.wh = window.innerHeight;

    let colors = ['#E9DCDC', '#E9E3DC', '#E9E8DC', '#DEE9DC', '#DCE9E7', '#E7DCE9', '#E9DCE5'];

    const loader = document.getElementById('loader');

    const logo = {
      el: document.querySelector('#loader svg'),
      paths: document.querySelectorAll('#loader svg *'),
    }

    document.body.style.overflow = 'hidden';

    loader.style.backgroundColor = colors[Math.floor(Math.random() * colors.length)];

    gsap.set(loader, {
      clip: `rect(0px ${this.ww}px ${this.wh}px 0px)`
    });

    gsap.fromTo(loader, {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.5,
    });

    gsap.set(logo.el, {
      y: 0,
      opacity: 1,
    });

    gsap.to(logo.el, {
      delay: 0.5,
      duration: 1,
      ease: 'power3.out',
    });

    gsap.fromTo(logo.paths, {
      drawSVG: `0% 0%`,
    }, {
      delay: 0.5,
      duration: 1,
      stagger: 0.02,
      drawSVG: `0% 100%`,
      ease: 'power3.out',
      onComplete: () => {
        done();
      },
    });
  }
}

export default Default;
