import gsap from 'gsap';

export default class Newsletter {
  constructor() {
    this.dropdown = document.querySelector('.s-newsletter-dropdown');
    this.select = document.querySelector('.s-newsletter .f-pr strong');
    this.options = document.querySelectorAll('.s-newsletter-dropdown li');
  }

  toggle = () => {
    if (this.dropdown.classList.contains('is-active')) {
      gsap.to(this.dropdown, { opacity: 0, pointerEvents: 'none', });
      this.dropdown.classList.remove('is-active');
    } else {
      gsap.to(this.dropdown, { opacity: 1, pointerEvents: 'all', });
      this.dropdown.classList.add('is-active');
    }
  }

  state = (e) => {
    const option = e.innerHTML;
    document.querySelector('.f-ft.hidden').value = option;
    this.select.innerHTML = option;
    this.toggle();
  }

  events = () => {
    this.select.addEventListener('click', this.toggle);

    [...this.options].forEach((e) => {
      e.addEventListener('click', () => {
        this.state(e);
      });
    });
  }

  init() {
    this.events();
  }
}
