import HeaderContact from './header-contact';
import HeaderImage from './header-image';
import HeaderProduct from './header-product';
import HeaderText from './header-text';
import HeaderVideo from './header-video';

export default class Header {
  constructor() {
    this.header = {
      contact: document.querySelector('.s-header-contact'),
      image: document.querySelector('.s-header-image'),
      product: document.querySelector('.s-header-product'),
      scroll: document.querySelector('.s-header-scroll'),
      text: document.querySelector('.s-header-text'),
      video: document.querySelector('.s-header-video'),
    }
  }

  destroy = () => {
    if (this.header.contact) {
      this.header.contact.destroy();
      this.header.contact = undefined;
    }
    if (this.header.image) {
      this.header.image = undefined;
    }
    if (this.header.product) {
      this.header.product.destroy();
      this.header.product = undefined;
    }
    if (this.header.text) {
      this.header.text = undefined;
    }
    if (this.header.video) {
      this.header.video.destroy();
      this.header.video = undefined;
    }
  }

  scroll = () => {
    const height = this.header.scroll.closest('header').clientHeight;

    window.scroll({
      left: 0,
      top: height,
      behavior: 'smooth'
    });
  }

  init() {
    if (this.header.contact) {
      this.header.contact = new HeaderContact();
      this.header.contact.init();
    }
    if (this.header.image) {
      this.header.image = new HeaderImage();
      this.header.image.init();
    }
    if (this.header.product) {
      this.header.product = new HeaderProduct();
      this.header.product.init();
    }
    if (this.header.text) {
      this.header.text = new HeaderText();
      this.header.text.init();
    }
    if (this.header.video) {
      this.header.video = new HeaderVideo();
      this.header.video.init();
    }
    if (this.header.scroll) {
      this.header.scroll.addEventListener('click', this.scroll);
    }
  }
}
