// styles
import './index.scss';

// modules
import Sniffer from 'sniffer';

// highway
import Highway from '@dogstudio/highway';

// renderers
import Renderer from './renderers/renderer';

// transitions
import Default from './transitions/default';
import Navigation from './transitions/navigation';

// components
import Cookies from './components/cookies/cookies';
import Cursor from './components/cursor/cursor';
import Loader from './components/loader/loader';
import Nav from './components/nav/nav';

// instances
import { instances } from './utils/store';

// sniffer
document.body.style.overflow = 'hidden';

Sniffer.addClasses(document.documentElement);

// cookies
let cookies;

cookies = new Cookies();
cookies.init();

// cursor
let cursor;

if (!Sniffer.isDevice) {
  cursor = new Cursor();
  cursor.init();
}

// nav
let nav;

nav = new Nav();
nav.init();

// preloader
let loader;

if (document.body.classList.contains('is-loading')) {
  loader = new Loader();
  loader.init();
}

// core
const H = new Highway.Core({
  renderers: {
    renderer: Renderer
  },
  transitions: {
    default: Default,
    contextual: {
      navigation: Navigation,
    }
  }
});

// menu
const nl = document.querySelectorAll('#nav a');
const ml = document.querySelectorAll('#menu a');

H.on('NAVIGATE_IN', ({ to, location }) => {

  [...nl].forEach((i) => {
    i.classList.remove('is-active');
    if (i.href === location.href) {
      i.classList.add('is-active');
    }
  });

  [...ml].forEach((i) => {
    i.classList.remove('is-active');
    if (i.href === location.href) {
      i.classList.add('is-active');
    }
  });

  if (Sniffer.isDevice) return;
  cursor.events();

});

H.on('NAVIGATE_END', ({ from, to, location }) => {
  if (instances.cookies === true) {
    // console.log('update anlaytics');
    if (typeof gtag !== 'undefined') {
      gtag('config', 'UA-166878310-1', {
        'page_path': location.pathname,
        'page_title': to.page.title,
        'page_location': location.href
      });
    }
  }
});
