import gsap from 'gsap';
import config from '../../utils/config';

export default class Call {
  constructor() {
    this.page = document.querySelector('.a-page');
    this.section = document.querySelector('.s-call');
    this.image = document.querySelector('.s-call-figure img');
  }

  set = () => {
    this.font = {
      p: this.section.querySelector('p'),
      h2: this.section.querySelector('h2'),
    }

    if (this.font.p) this.font.p.classList.add('f-pr');
    if (this.font.h2) this.font.h2.classList.add('f-hm');
  }

  render = () => {
    const py = Math.abs(config.page.getBoundingClientRect().top);
    const sb = this.section.getBoundingClientRect();

    const d = (config.wh + py) - (sb.top + py);
    const p = d / ((config.wh + sb.height) / 100);

    if (p > 0 && p < 100) {
      this.image.style.transform = `translate3d(0, ${p / 2}%, 0)`;
    }
  }

  resize = () => {
    config.wh = window.innerHeight;
  }

  create = () => {
    gsap.ticker.add(this.render);
    window.addEventListener('resize', this.resize, { passive: true });
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
    window.removeEventListener('resize', this.resize);
  }

  init() {
    this.set();
    this.create();
  }
}
