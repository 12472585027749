import Sniffer from 'sniffer';
import Highway from '@dogstudio/highway';

class Navigation extends Highway.Transition {
  in({ from, trigger, to, done }) {
    from.remove();

    if (Sniffer.isDevice) {
      this.app = document.getElementById('app');
      this.app.scrollTo(0, 0);
    }

    if (trigger.classList.contains('is-sub')) {
      done();
    }

    if (Sniffer.isPhone) {
      done();
    }

    if (!Sniffer.isPhone) {
      document.body.classList.remove('is-loader');
      setTimeout(() => {
        done();
      }, 1000);
    }
  }

  out({ from, done }) {
    done();
  }
}

export default Navigation;
