import gsap from 'gsap';
import Sniffer from 'sniffer';
import CustomEase from 'gsap/CustomEase';
import config from './config';

gsap.registerPlugin(CustomEase);

export default class Buttons {
  constructor() {
    this.bp = document.querySelectorAll('.f-bp');
    this.bs = document.querySelectorAll('.f-bs');

    CustomEase.create('exo', config.ease);
  }

  enterBp = (i) => {
    const label = i.querySelector('.f-bp-label');
    const arrow = i.querySelectorAll('.f-bp-arrow');
    const tl = gsap.timeline({ defaults: { ease: 'exo', duration: 0.5, }, });

    tl
      .to(label, { x: 0, }, 0)
      .to(arrow[0], { x: -window.innerWidth * 0.01563, opacity: 1, }, 0)
      .to(arrow[1], { x: window.innerWidth * 0.01563, opacity: 0, }, 0);
  }

  leaveBp = (i) => {
    const label = i.querySelector('.f-bp-label');
    const arrow = i.querySelectorAll('.f-bp-arrow');
    const tl = gsap.timeline({ defaults: { ease: 'exo', duration: 0.5, }, });

    tl
      .to(label, { x: -window.innerWidth * 0.01563, }, 0)
      .to(arrow[0], { x: -window.innerWidth * 0.03125, opacity: 0, }, 0)
      .to(arrow[1], { x: 0, opacity: 1, }, 0);
  }

  enterBs = (i) => {
    const line = i.querySelector('.f-bs-line');
    const label = i.querySelector('.f-bs-label');
    const arrow = i.querySelectorAll('.f-bs-arrow');
    const tl = gsap.timeline({ defaults: { ease: 'exo', duration: 0.5, }, });

    tl
      .to(line, { x: i.clientWidth - line.clientWidth, }, 0)
      .to(label, { x: -window.innerWidth * 0.03472, }, 0)
      .to(arrow[0], { x: window.innerWidth * 0.03472, opacity: 0, }, 0)
      .to(arrow[1], { x: 0, opacity: 1, }, 0);
  }

  leaveBs = (i) => {
    const line = i.querySelector('.f-bs-line');
    const label = i.querySelector('.f-bs-label');
    const arrow = i.querySelectorAll('.f-bs-arrow');
    const tl = gsap.timeline({ defaults: { ease: 'exo', duration: 0.5, }, });

    tl
      .to(line, { x: 0, }, 0)
      .to(label, { x: 0, }, 0)
      .to(arrow[0], { x: 0, opacity: 1, }, 0)
      .to(arrow[1], { x: -window.innerWidth * 0.03472, opacity: 0, }, 0);
  }

  destroy = () => { }

  eventsBp = (i) => {
    i.addEventListener('mouseenter', () => {
      this.enterBp(i);
    });

    i.addEventListener('mouseleave', () => {
      this.leaveBp(i);
    });
  }

  eventsBs = (i) => {
    i.addEventListener('mouseenter', () => {
      this.enterBs(i);
    });

    i.addEventListener('mouseleave', () => {
      this.leaveBs(i);
    });
  }

  init = () => {
    if (Sniffer.isDevice) return;

    [...this.bp].forEach((i) => {
      this.eventsBp(i);
    });

    [...this.bs].forEach((i) => {
      this.eventsBs(i);
    });
  }
}
