import gsap from 'gsap';
import Sniffer from 'sniffer';
import Player from '../player/player';

export default class HeaderVideo {
  constructor() {
    this.page = document.querySelector('.a-page');
    this.badge = document.querySelector('.s-header-badge');
    this.scroll = document.querySelector('.s-header-scroll');
    this.section = document.querySelector('.s-header-video');
    this.preview = document.querySelector('.s-header-preview');

    this.following = true;

    this.tX = window.innerWidth / 2 - this.badge.clientWidth / 2;
    this.tY = window.innerHeight - this.badge.clientHeight * 2;

    this.pX = window.innerWidth / 2 - this.badge.clientWidth / 2;
    this.pY = window.innerHeight - this.badge.clientHeight * 2;

    this.math = {
      lerp: (a, b, n) => (1 - n) * a + n * b,
    };
  }

  set = () => {
    this.font = {
      h2: this.section.querySelector('h2'),
    }

    this.font.h2.classList.add('f-hl');
    gsap.to(this.badge, { opacity: 1, duration: 1, });

    this.vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${this.vh}px`);
  }

  track = (e) => {
    if (this.following === false) {
      this.tX = window.innerWidth / 2 - this.badge.clientWidth / 2;
      this.tY = window.innerHeight - this.badge.clientHeight * 2;
      return;
    }
    this.tX = e.clientX - this.badge.clientWidth / 2;
    this.tY = e.clientY - this.badge.clientHeight / 2;
  }

  render = () => {
    this.wY = this.page.getBoundingClientRect().top;
    this.pX = this.math.lerp(this.pX, this.tX, 0.05);

    if (this.following === true) {
      this.pY = this.math.lerp(this.pY, this.tY - this.wY, 0.05);
      this.badge.style.transform = `translate3d(${this.pX}px, ${this.pY}px, 0)`;
    } else {
      this.pY = this.math.lerp(this.pY, this.tY, 0.05);
      this.badge.style.transform = `translate3d(${this.pX}px, ${this.pY}px, 0)`;
    }
  }

  create = () => {
    gsap.ticker.add(this.render);

    this.scroll.addEventListener('mouseenter', () => {
      this.following = false;
    });

    this.scroll.addEventListener('mouseleave', () => {
      this.following = true;
    });

    this.section.addEventListener('mouseenter', () => {
      this.following = true;
    });

    this.section.addEventListener('mouseleave', () => {
      this.following = false;
    });
  }

  resize = () => {
    this.vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${this.vh}px`);
  }

  events = () => {
    window.addEventListener('mousemove', this.track);
    window.addEventListener('resize', this.resize, { passive: true });
  }

  destroy = () => {
    gsap.ticker.remove(this.render);

    window.removeEventListener('resize', this.resize);
    window.removeEventListener('mousemove', this.track);
  }

  init() {
    this.set();
    this.events();

    const player = new Player();
    player.init();

    if (Sniffer.isDevice) return;
    this.create();
  }
}
