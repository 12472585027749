import gsap from 'gsap';
import Sniffer from 'sniffer';
import SplitText from 'gsap/SplitText';
import config from '../../utils/config';

export default class Values {
  constructor() {
    this.arr = [];

    this.section = document.querySelector('.s-values');

    this.nr = this.section.querySelectorAll('.f-nr');
    this.no = this.section.querySelectorAll('.f-st');
  }

  set = () => {
    this.font = {
      p: this.section.querySelectorAll('p'),
      h2: this.section.querySelectorAll('h2'),
    };

    [...this.no].forEach((i) => {
      this.arr.push(i);
    });

    [...this.nr].forEach((i) => {
      i = new SplitText(i, { type: 'chars' });
      this.arr.push(i.chars);
    });

    [...this.font.h2].forEach((i) => {
      i.classList.add('f-hs');
      i = new SplitText(i, { type: 'lines' });
      this.arr.push(i.lines);
    });

    [...this.font.p].forEach((i) => {
      i.classList.add('f-pr');
      i = new SplitText(i, { type: 'lines' });
      this.arr.push(i.lines);
    });

    if (Sniffer.isPhone) return;

    gsap.set([this.arr], {
      opacity: 0, y: config.ww * 0.0347, rotation: 5, transformOrigin: '0 0',
    });
  }

  inview = () => {
    const y = Math.abs(config.page.getBoundingClientRect().top);
    const b = this.section.getBoundingClientRect();

    const d = (config.wh + y) - (b.top + y);
    const p = d / ((config.wh + b.height) / 100);

    if (p > 10 && p < 100) {
      this.animate();
      this.destroy();
    }
  }

  animate = () => {
    gsap.to([this.arr], { opacity: 1, y: 0, rotation: 0, stagger: 0.05, duration: 1.5, ease: 'expo.out', });
  }

  create = () => {
    gsap.ticker.add(this.inview);
  }

  destroy = () => {
    gsap.ticker.remove(this.inview);
  }

  init() {
    this.set();
    if (Sniffer.isPhone) return;
    this.create();
  }
}
