import gsap from 'gsap';
import Sniffer from 'sniffer';

export default class Player {
  constructor() {
    this.app = document.getElementById('app');
    this.page = document.querySelector('.a-page');

    this.banner = document.querySelector('.s-video');
    this.header = document.querySelector('.s-header-preview');

    this.cursor = document.querySelector('#cursor div');

    this.visible = false;
    this.request = new XMLHttpRequest();
  }

  load = () => {
    this.data = document.querySelector('[data-video]').dataset.video;
    this.request.open('POST', '' + document.location.origin + '/wp-admin/admin-ajax.php', true);
    this.request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;');

    this.request.onload = () => {
      this.src.innerHTML = this.request.response;
      this.video = this.src.querySelector('video');

      if (Sniffer.isDevice) {
        this.native();
      } else {
        this.ui();
      }
    };

    this.request.send('action=player&id=' + this.data + '');
  }

  native = () => {
    this.video.addEventListener('webkitendfullscreen', () => {
      this.hide();
    });
    if (Sniffer.isDroid) {
      this.video.requestFullscreen();
      this.video.addEventListener('fullscreenchange', () => {
        if (!document.fullscreenElement) {
          this.hide();
          this.video = undefined;
        }
      });
    }
  }

  ui = () => {
    this.range = document.createElement('input');
    this.range.classList.add('p-range');
    this.range.type = 'range';
    this.range.min = 0;
    this.range.value = 0;
    this.range.step = 0.05;

    this.progress = document.createElement('progress');
    this.progress.classList.add('p-progress');
    this.progress.max = 100;

    this.pause = document.createElement('button');
    this.pause.classList.add('p-pause');
    this.pause.innerHTML = 'Pause';

    this.controls.appendChild(this.range);
    this.controls.appendChild(this.progress);
    this.controls.appendChild(this.pause);

    this.range.addEventListener('input', () => {
      this.updateProgress = null;
      this.video.currentTime = this.range.value / 10;
    });

    this.video.addEventListener('play', () => {
      this.range.max = Math.round(this.video.duration * 10);
      this.progress.max = Math.round(this.video.duration * 10);
    });

    this.src.addEventListener('mouseenter', this.enter);
    this.src.addEventListener('mouseleave', this.leave);

    this.pause.addEventListener('click', () => {
      if (this.video.paused) {
        this.video.play();
        this.pause.innerHTML = 'Pause';
      } else {
        this.video.pause();
        this.pause.innerHTML = 'Play';
      }
    });

    this.video.addEventListener('click', this.hide);
    this.video.addEventListener('ended', this.hide);

    gsap.ticker.add(this.render);
  }

  enter = () => {
    this.cursor.classList.add('c-close');
    gsap.to(this.cursor, { opacity: 1, duration: 0.5, });
  }

  leave = () => {
    this.cursor.classList.remove('c-close');
    gsap.to(this.cursor, { opacity: 0, duration: 0.5, });
  }

  render = () => {
    this.range.value = this.video.currentTime * 10;
    this.progress.value = this.video.currentTime * 10;
  }

  hide = () => {
    this.leave();

    gsap.to(this.player, {
      opacity: 0,
      duration: 1,
      onComplete: () => {
        this.destroy();
      },
    })
  }

  create = () => {
    if (this.player) return;

    this.player = document.createElement('div');
    this.player.setAttribute('id', 'player');

    this.src = document.createElement('div');
    this.src.classList.add('p-src');
    this.player.appendChild(this.src);

    this.controls = document.createElement('div');
    this.controls.classList.add('p-controls');
    this.player.appendChild(this.controls);

    this.app.insertAdjacentElement('afterEnd', this.player);
    gsap.set(this.player, { opacity: 0 });

    this.load();

    document.body.style.overflow = 'hidden';

    gsap.to(this.player, { opacity: 1 });

    if (this.header) {
      window.scrollTo(0, 0);
    }
  }

  destroy = () => {
    this.player.remove();
    this.player = null;

    gsap.ticker.remove(this.render);
    document.body.style.overflow = '';
  }

  events = () => {
    if (this.header) {
      this.header.addEventListener('click', this.create);
    }
    if (this.banner) {
      this.banner.addEventListener('click', this.create);
    }
  }

  init() {
    this.events();
  }
}
