import gsap from 'gsap';
import Sniffer from 'sniffer';
import imagesLoaded from 'imagesloaded';

export default class Carousel {
  constructor() {
    this.page = document.querySelector('.a-page');
    this.section = document.querySelector('.s-carousel');

    this.nav = {
      el: document.querySelector('.s-carousel-nav'),
      prev: document.querySelector('.s-carousel-prev'),
      next: document.querySelector('.s-carousel-next'),
    }

    this.carousel = {
      item: document.querySelectorAll('.s-carousel-item'),
      container: document.querySelector('.s-carousel-container'),
    }

    this.array = [];
    this.current = 0;
    this.animating = false;

    this.total = this.carousel.item.length;
  }

  set = () => {
    this.font = {
      h2: this.section.querySelector('h2'),
    }

    if (this.font.h2) this.font.h2.classList.add('f-hm');
  }

  height = () => {
    let height = 0;

    [...this.carousel.item].forEach((i) => {
      let h = i.clientHeight;
      if (h > height) {
        height = h;
      }
    });

    this.carousel.container.style.height = `${height}px`;

    if (Sniffer.isDevice) return;

    document.body.style.height = `${this.page.getBoundingClientRect().height}px`;
  }

  slider = () => {
    if (this.array.length >= 1) {
      this.wrap = document.createElement('div');
      this.wrap.classList.add('s-carousel-slide');

      this.array[0].before(this.wrap);
      this.array.forEach(x => this.wrap.append(x));
      this.array.length = 0;
    }
  }

  create = () => {
    if (Sniffer.isPhone) {
      this.length = 1;
    } else {
      this.length = 3;
    }

    [...this.carousel.item].forEach((item) => {
      this.array.push(item);
      if (this.array.length === this.length) {
        this.slider();
      }
    });

    this.slider();

    this.slides = document.querySelectorAll('.s-carousel-slide');
    this.slides[this.current].style.visibility = 'inherit';

    if (Sniffer.isPhone) {
      if (this.total <= 1) {
        this.nav.el.style.visibility = 'hidden';
      }
      return;
    };

    if (this.total <= 3) {
      this.nav.el.style.visibility = 'hidden';
    }
  }

  prev = () => {
    if (this.animating === true) return;
    this.animating = true;

    let slides = this.slides[this.current].querySelectorAll('.s-carousel-item');

    gsap.to(slides, { duration: 1.5, stagger: -0.05, ease: 'expo.inOut', x: window.innerWidth, });

    this.slides[this.current].style.pointerEvents = 'none';

    if (this.current === 0) {
      this.current = this.slides.length - 1;
    } else {
      this.current--;
    }

    this.slides[this.current].style.visibility = 'inherit';

    slides = this.slides[this.current].querySelectorAll('.s-carousel-item');

    gsap.set(slides, { x: -window.innerWidth, });
    gsap.to(slides, {
      x: 0, delay: 0.1, duration: 1.5, stagger: -0.05, ease: 'expo.inOut',
      onComplete: () => {
        this.animating = false;
      },
    });

    this.slides[this.current].style.pointerEvents = '';
  }

  next = () => {
    if (this.animating === true) return;
    this.animating = true;

    let slides = this.slides[this.current].querySelectorAll('.s-carousel-item');

    gsap.to(slides, { duration: 1.5, stagger: 0.05, ease: 'expo.inOut', x: -window.innerWidth, });

    this.slides[this.current].style.pointerEvents = 'none';

    if (this.current === this.slides.length - 1) {
      this.current = 0;
    } else {
      this.current++;
    }

    this.slides[this.current].style.visibility = 'visible';

    slides = this.slides[this.current].querySelectorAll('.s-carousel-item');

    gsap.set(slides, { x: window.innerWidth, });
    gsap.to(slides, {
      x: 0, delay: 0.1, duration: 1.5, stagger: 0.05, ease: 'expo.inOut',
      onComplete: () => {
        this.animating = false;
      },
    });

    this.slides[this.current].style.pointerEvents = '';
  }

  resize = () => {
    this.height();
  }

  destroy = () => {
    this.nav.prev.removeEventListener('click', this.prev);
    this.nav.next.removeEventListener('click', this.next);
    window.removeEventListener('resize', this.resize);
  }

  events = () => {
    this.nav.prev.addEventListener('click', this.prev);
    this.nav.next.addEventListener('click', this.next);
    window.addEventListener('resize', this.resize, { passive: true });
  }

  init() {
    this.set();
    this.create();

    imagesLoaded(this.section, () => {
      this.height();
    });

    if (Sniffer.isPhone) {
      this.events();
    };

    if (this.total <= 3) return;

    this.events();
  }
}
