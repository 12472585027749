export default class Help {
  constructor() {
    this.section = document.querySelectorAll('.s-help');
  }

  set = () => {
    this.font = {
      p: document.querySelectorAll('.s-help-column p'),
    }

    if (this.font.p) {
      [...this.font.p].forEach((i) => {
        i.classList.add('f-pr');
      });
    }
  }

  init() {
    this.set();
  }
}
