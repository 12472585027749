import gsap from 'gsap';
import Sniffer from 'sniffer';
import CustomEase from 'gsap/CustomEase';
import imagesLoaded from 'imagesloaded';
import config from './config';

gsap.registerPlugin(CustomEase);

export default class Zoom {
  constructor() {
    this.sections = document.querySelectorAll('[data-zoom]');
    this.cursor = document.querySelector('#cursor div');

    CustomEase.create('exo', config.ease);

    this.tY = 0;
    this.pY = 0;

    this.ww = window.innerWidth;
    this.wh = window.innerHeight;
  }

  create = () => {
    document.body.style.overflow = 'hidden';

    this.zoom = document.createElement('div');
    this.zoom.setAttribute('id', 'zoom');
    document.body.appendChild(this.zoom);

    this.viewer = document.createElement('div');
    this.viewer.classList.add('z-viewer');
    this.zoom.appendChild(this.viewer);

    this.image = document.createElement('img');
    this.image.classList.add('z-image');
    this.image.src = this.element.dataset.zoom;
    this.image.style.visibility = 'hidden';
    this.viewer.appendChild(this.image);

    imagesLoaded(this.image, () => {
      this.set();
    });
  }

  set = () => {
    this.sb = this.image.getBoundingClientRect();
    this.eb = this.element.getBoundingClientRect();

    gsap.set(this.image, { scale: this.eb.width / this.sb.width, });

    this.tx = (this.sb.width / (this.sb.width - this.eb.width)) * (this.eb.left - (0 * this.eb.width) / this.sb.width);
    this.ty = (this.sb.height / (this.sb.height - this.eb.height)) * (this.eb.top - (0 * this.eb.height) / this.sb.height);

    this.image.style.transformOrigin = `${this.tx}px ${this.ty}px`;
    this.image.style.visibility = 'inherit';

    gsap.set(this.viewer, {
      clip: `rect(${this.eb.top}px ${this.eb.left + this.eb.width}px ${this.eb.bottom}px ${this.eb.left}px)`
    });

    this.open();
  }

  open = () => {
    gsap.to(this.image, {
      scale: 1,
      ease: 'exo',
      duration: 1.5,
      autoRound: false,
    });

    gsap.to(this.viewer, {
      delay: 0.1,
      ease: 'exo',
      duration: 1.5,
      autoRound: false,
      onComplete: this.interact,
      clip: `rect(0px ${this.ww}px ${this.wh}px 0px)`,
    });

    this.image.addEventListener('click', this.close);
  }

  close = () => {
    gsap.ticker.remove(this.raf);

    gsap.to(this.image, {
      opacity: 0,
      duration: 0.5,
      onComplete: this.reset,
    });
  }

  enter = () => {
    this.cursor.setAttribute('class', '');
    this.cursor.classList.add('c-close');
    gsap.to(this.cursor, { opacity: 1, duration: 0.5, });
  }

  leave = () => {
    this.cursor.setAttribute('class', '');
    gsap.to(this.cursor, { opacity: 0, duration: 0.5, });
  }

  mouse = (e) => {
    this.tY = e.clientY;
  }

  reset = () => {
    this.zoom.remove();
    document.body.style.overflow = '';
    window.removeEventListener('mousemove', this.mouse);

    this.tY = 0;
    this.pY = 0;

    this.leave();
  }

  interact = () => {
    this.enter();
    window.addEventListener('mousemove', this.mouse);
    gsap.ticker.add(this.raf);
  }

  raf = () => {
    this.pY += (this.tY - this.pY) * 0.07;
    this.transform = (this.wh - this.sb.height) * (this.pY / this.wh);
    this.image.style.transform = `translate3d(0, ${this.transform}px, 0)`;
  }

  destroy = () => {
    gsap.ticker.remove(this.raf);
  }

  events = () => {
    [...this.sections].forEach((i) => {
      i.addEventListener('click', () => {
        this.element = i;
        this.create();
      });
    });
  }

  init() {
    if (Sniffer.isDevice) return;
    if (this.sections.length === 0) return;
    this.events();
  }
}