import gsap from 'gsap';
import Sniffer from 'sniffer';
import imagesLoaded from 'imagesloaded';
import CustomEase from 'gsap/CustomEase';
import config from '../../utils/config';
import Scroll from '../scroll/scroll';

gsap.registerPlugin(CustomEase);

export default class Lookbook {
  constructor() {
    this.sections = document.querySelectorAll('.js-lookbook');
    this.cursor = document.querySelector('#cursor div');

    CustomEase.create('exo', config.ease);

    this.tY = 0;
    this.pY = 0;

    this.ww = window.innerWidth;
    this.wh = window.innerHeight;

    this.request = new XMLHttpRequest();
  }

  init() {
    if (Sniffer.isDevice) return;
    if (this.sections.length === 0) return;

    this.events();
  }

  events = () => {
    [...this.sections].forEach((i) => {
      i.addEventListener('click', () => {
        this.element = i;
        this.create();
      });
    });
  }

  create = () => {
    document.body.style.overflow = 'hidden';
    this.cache = Math.abs(document.querySelector('.a-page').getBoundingClientRect().top);

    this.lookbook = document.createElement('div');
    this.lookbook.setAttribute('id', 'lookbook');
    document.body.appendChild(this.lookbook);

    this.viewer = document.createElement('div');
    this.viewer.classList.add('l-viewer');
    this.lookbook.appendChild(this.viewer);

    this.image = document.createElement('img');
    this.image.classList.add('l-image');
    this.image.src = this.element.dataset.image;
    this.image.style.visibility = 'hidden';
    this.viewer.appendChild(this.image);

    this.panel = document.createElement('div');
    this.panel.classList.add('l-panel');
    this.lookbook.appendChild(this.panel);

    imagesLoaded(this.image, () => {
      this.set();
    });
  }

  set = () => {
    this.sb = this.image.getBoundingClientRect();
    this.eb = this.element.getBoundingClientRect();

    gsap.set(this.image, { scale: this.eb.width / this.sb.width, });

    this.tx = (this.sb.width / (this.sb.width - this.eb.width)) * (this.eb.left - (0 * this.eb.width) / this.sb.width);
    this.ty = (this.sb.height / (this.sb.height - this.eb.height)) * (this.eb.top - (0 * this.eb.height) / this.sb.height);

    this.image.style.transformOrigin = `${this.tx}px ${this.ty}px`;
    this.image.style.visibility = 'inherit';

    gsap.set(this.viewer, {
      clip: `rect(${this.eb.top}px ${this.eb.left + this.eb.width}px ${this.eb.bottom}px ${this.eb.left}px)`
    });

    gsap.set(this.panel, { xPercent: 100, });

    this.open();
  }

  open = () => {
    gsap.to(this.image, { scale: 1, ease: 'exo', duration: 1.5, autoRound: false, });
    gsap.to(this.viewer, { delay: 0.1, ease: 'exo', duration: 1.5, autoRound: false, onComplete: this.load, clip: `rect(0px ${this.ww}px ${this.wh}px 0px)`, });
  }

  load = () => {
    this.data = this.element.dataset.products;

    this.request.open('POST', '' + document.location.origin + '/wp-admin/admin-ajax.php', true);
    this.request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;');

    this.request.onload = () => {
      this.panel.innerHTML = this.request.response;
      gsap.to(this.panel, { xPercent: 0, ease: 'exo', duration: 1.5, onComplete: this.scroller, });
    };

    this.mover();
    this.enter();

    this.cursor.classList.add('c-close');

    this.request.send('action=lookbook&id=' + this.data + '');
  }

  mover = () => {
    window.addEventListener('mousemove', this.mouse);
    gsap.ticker.add(this.raf);
  }

  enter = () => {
    this.cursor.setAttribute('class', '');
    gsap.to(this.cursor, { opacity: 1, duration: 0.5, });
  }

  leave = () => {
    this.cursor.setAttribute('class', '');
    gsap.to(this.cursor, { opacity: 0, duration: 0.5, });
  }

  scroller = () => {
    document.body.style.overflow = '';

    this.products = document.querySelectorAll('.l-product');

    [...this.products].forEach((i) => {
      i.addEventListener('mouseenter', () => {
        this.enter();
        this.cursor.classList.add('c-more');
      });
      i.addEventListener('mouseleave', this.leave);
      i.addEventListener('click', () => {
        this.product = i;
        this.transition();
      });
    });

    this.scroll = new Scroll();
    this.scroll.overlay();

    this.image.addEventListener('mouseleave', this.leave);
    this.image.addEventListener('click', this.close);
    this.image.addEventListener('mouseenter', () => {
      this.enter();
      this.cursor.classList.add('c-close');
    });
  }

  transition = () => {
    gsap.ticker.remove(this.raf);

    this.scroll.destroy();
    this.image.style.pointerEvents = 'none';

    [...this.products].forEach((i) => {
      i.style.pointerEvents = 'none';
    });

    let permalink = this.product.querySelector('a').href;
    let link = this.element.parentElement.querySelector('.js-product');

    link.href = permalink;
    link.click();
  }

  close = () => {
    gsap.ticker.remove(this.raf);

    this.scroll.destroy();

    gsap.to(this.image, { opacity: 0, duration: 0.5, });
    gsap.to(this.panel, { ease: 'exo', xPercent: 100, duration: 1.5, onComplete: this.reset, });

    this.leave();
  }

  mouse = (e) => {
    this.tY = e.clientY;
  }

  raf = () => {
    this.pY += (this.tY - this.pY) * 0.07;
    this.transform = (this.wh - this.sb.height) * (this.pY / this.wh);
    this.image.style.transform = `translate3d(0, ${this.transform}px, 0)`;
  }

  reset = () => {
    document.body.style.overflow = '';

    window.removeEventListener('mousemove', this.mouse);

    this.tY = 0;
    this.pY = 0;

    this.scroll = new Scroll(this.cache);
    this.scroll.init();

    this.lookbook.remove();
  }

  destroy = () => {
    if (this.lookbook) {
      this.lookbook.remove();
      this.lookbook = undefined;
      gsap.ticker.remove(this.raf);
    }
  }
}
