import gsap from 'gsap';
import Sniffer from 'sniffer';
import SplitText from 'gsap/SplitText';
import CustomEase from 'gsap/CustomEase';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';

import config from '../../utils/config';

gsap.registerPlugin(CustomEase);
gsap.registerPlugin(DrawSVGPlugin);

class Hero {
  constructor() {
    this.page = document.querySelector('.a-page');

    this.menu = document.getElementById('menu');
    this.burger = document.getElementById('burger');
    this.loader = document.getElementById('loader');

    this.hero = document.querySelector('.s-hero');
    this.head = document.querySelector('.s-hero-head');
    this.title = document.querySelector('.s-hero-head h3');
    this.images = document.querySelector('.s-hero-images');
    this.image = document.querySelectorAll('.s-hero-image');
    this.wrapper = document.querySelector('.s-hero-wrapper');
    this.heading = document.querySelector('.s-hero-head h2');
    this.logo = document.querySelectorAll('.s-hero-head .i-logo *');

    CustomEase.create('exo', config.ease);

    this.delta = 0;
    this.transform = 0;
  }

  set = () => {
    this.font = {
      h3: this.hero.querySelector('h3'),
      h2: this.hero.querySelector('h2'),
    }

    if (this.font.h3) this.font.h3.classList.add('f-st');
    if (this.font.h2) this.font.h2.classList.add('f-hl');
  }

  intro() {
    const ib = this.images.getBoundingClientRect();
    const ot = ib.top - (config.wh / 2 - ib.height / 2);

    this.stack = [];
    this.stack.push(this.image[0]);
    this.stack.push(this.image[1]);
    this.stack.push(this.image[2]);
    this.stack.push(this.image[3]);
    this.stack.push(this.image[4]);

    [...this.stack].forEach((i) => {
      const b = i.getBoundingClientRect();
      gsap.set(i, { y: -ot, x: (config.ww + config.ww * 0.055) - b.left, });
    });

    gsap.set(this.title, { opacity: 0, });
    gsap.set([this.burger, this.menu], { y: -config.wh, });
    gsap.set(this.loader, { clip: `rect(${config.wh}px ${config.ww}px ${config.wh}px 0px)`, });

    gsap.to(this.stack, { x: 0, duration: 1.5, ease: 'exo', onComplete: this.create, });
    gsap.to(this.stack, { y: 0, delay: 1.5, duration: 2, ease: 'exo', stagger: { amount: 0.2, from: 'center' }, });

    this.heading = new SplitText(this.heading, { type: 'lines,words,chars' });

    [...this.heading.words].forEach((i) => {
      gsap.set(i, { opacity: 0, y: -window.innerWidth * 0.104, className: '+=f-hl-word', });
    });

    [...this.heading.chars].forEach((i) => {
      i.classList.add('f-hl-char');
    });

    gsap.to(this.heading.words, { opacity: 1, y: 0, delay: 2.2, stagger: -0.02, duration: 1.25, ease: 'power3.out' });

    [...this.heading.lines].forEach((i) => {
      let arr = [];

      const g = i.querySelectorAll('.f-hl-word');
      const w = g.length - 1;
      const l = g[w].querySelectorAll('.f-hl-char');

      if (l[l.length - 1]) { arr.push(l[l.length - 1]); }
      if (l[l.length - 2]) { arr.push(l[l.length - 2]); }
      if (l[l.length - 3]) { arr.push(l[l.length - 3]); }

      gsap.fromTo(arr,
        { y: -window.innerWidth * 0.02604, opacity: 0, },
        {
          opacity: 1, y: 0, stagger: -0.2, delay: 2.3, duration: 1.25, ease: 'power3.out',
        });
    });

    gsap.to([this.burger, this.menu], { delay: 2.1, y: 0, duration: 1, ease: 'expo', });
    gsap.to(this.title, { delay: 2.7, opacity: 1, duration: 1, stagger: 0.05, });

    gsap.fromTo(this.logo,
      { drawSVG: `0% 0%`, },
      {
        delay: 2.7, duration: 1, stagger: 0.02, drawSVG: `0% 100%`, ease: 'power3.out',
        onComplete: () => {
          document.body.style.overflow = '';
        }
      }
    );
  }

  clear = () => {
    this.delta = 0;
    this.velo = null;
  }

  animate = () => {
    this.delay = 50;

    if (this.velo != null) {
      this.delta = this.scrollY - this.velo;
    }

    if (this.delta < 0) {
      this.delta = 0;
    }

    this.velo = this.scrollY;

    clearTimeout(this.timer);

    this.timer = setTimeout(this.clear, this.delay);

    if (this.transform > this.wrapper.getBoundingClientRect().width / 3) {
      this.transform = 0;
    }

    this.transform += 0.3 + (this.delta / 2);

    this.wrapper.style.transform = `translate3d(-${this.transform}px, 0, 0)`;
  }

  render = () => {
    this.scrollY = Math.abs(this.page.getBoundingClientRect().top);

    const bounding = this.hero.getBoundingClientRect();
    const distance = (config.wh + this.scrollY) - (bounding.top + this.scrollY);
    const percentage = distance / ((config.wh + bounding.height) / 100);

    if (percentage > 0 && percentage < 100) {
      this.animate();
    }
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
  }

  create = () => {
    gsap.ticker.add(this.render);
  }

  init() {
    this.set();

    if (document.body.classList.contains('is-loading')) {
      if (!Sniffer.isPhone) {
        this.intro();
      } else {
        this.create();
      }
      setTimeout(() => {
        document.body.classList.remove('is-loading');
      }, 100);
    } else {
      this.create();
    }
  }
}

export default Hero;
