import gsap from 'gsap';
import Sniffer from 'sniffer';

export default class Faq {
  constructor() {
    this.page = document.querySelector('.a-page');
    this.section = document.querySelector('.s-faq');

    this.questions = this.section.querySelectorAll('.s-faq-question');
    this.titles = this.section.querySelectorAll('.s-faq-question .f-hs');
  }

  set = () => {
    this.font = {
      p: this.section.querySelectorAll('p'),
      ul: this.section.querySelectorAll('ul'),
    };

    [...this.font.p].forEach((i) => {
      i.classList.add('f-pr');
    });

    [...this.font.ul].forEach((i) => {
      i.classList.add('f-pr');
    });

    if (Sniffer.isPhone) {
      [...this.questions].forEach((i) => {
        let height = i.querySelector('.f-hs').clientHeight + window.innerWidth * 0.08333;
        i.style.height = `${height}px`;
      });
    };
  }

  toggle = (i) => {
    let height = 0;
    let duration = 1;

    gsap.to(this.section.querySelectorAll('.f-hs svg'), { rotation: 90, duration, ease: 'expo.inOut' });

    if (Sniffer.isPhone) {
      height = Math.round(i.clientHeight + window.innerWidth * 0.08333);
      [...this.questions].forEach((i) => {
        let height = i.querySelector('.f-hs').clientHeight + window.innerWidth * 0.08333;
        gsap.to(i, { height, duration, ease: 'expo.inOut' });
      });
    } else {
      height = i.clientHeight;
      gsap.to(this.questions, {
        height, duration, ease: 'expo.inOut',
        onComplete: () => {
          document.body.style.height = `${this.page.clientHeight}px`;
        },
      });
    }

    if (i.parentElement.clientHeight > height) return;

    height = i.parentElement.querySelector('.s-faq-content').clientHeight;
    height = height + i.clientHeight;

    gsap.to(i.parentElement, { height, duration, ease: 'expo.inOut' });
    gsap.to(i.parentElement.querySelector('.f-hs svg'), { rotation: -90, duration, ease: 'expo.inOut' });

    if (Sniffer.isPhone) return;

    setTimeout(() => {
      let offset = i.getBoundingClientRect().top + Math.abs(this.page.getBoundingClientRect().top);
      window.scrollTo(0, offset);
      document.body.style.height = `${this.page.clientHeight}px`;
    }, duration * 1000);
  }

  events = () => {
    [...this.titles].forEach((i) => {
      i.addEventListener('click', () => {
        this.toggle(i);
      });
    });
  }

  init() {
    this.set();
    this.events();
  }
}
