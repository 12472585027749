import gsap from 'gsap';
import Sniffer from 'sniffer';
import Player from '../player/player';

export default class Video {
  constructor() {
    this.page = document.querySelector('.a-page');
    this.section = document.querySelector('.s-video');
    this.badge = document.querySelector('.s-video-badge');
    this.preview = document.querySelector('.s-video-preview');

    this.following = false;

    this.tX = this.section.clientWidth / 2 - this.badge.clientWidth / 2;
    this.tY = this.section.clientHeight / 2 - this.badge.clientHeight / 2;

    this.pX = this.section.clientWidth / 2 - this.badge.clientWidth / 2;
    this.pY = this.section.clientHeight / 2 - this.badge.clientHeight / 2;

    this.math = {
      lerp: (a, b, n) => (1 - n) * a + n * b,
    };
  }

  track = (e) => {
    if (this.following === false) {
      this.tX = this.section.clientWidth / 2 - this.badge.clientWidth / 2;
      this.tY = this.section.clientHeight / 2 - this.badge.clientHeight / 2;
      return;
    }
    this.tX = e.clientX - this.badge.clientWidth / 2;
    this.tY = e.clientY - this.badge.clientHeight / 2;
  }

  render = () => {
    this.wY = this.section.getBoundingClientRect().top;
    this.pX = this.math.lerp(this.pX, this.tX, 0.05);

    if (this.following === true) {
      this.pY = this.math.lerp(this.pY, this.tY - this.wY, 0.05);
      this.badge.style.transform = `translate3d(${this.pX}px, ${this.pY}px, 0)`;
    } else {
      this.pY = this.math.lerp(this.pY, this.tY, 0.05);
      this.badge.style.transform = `translate3d(${this.pX}px, ${this.pY}px, 0)`;
    }
  }

  create = () => {
    gsap.ticker.add(this.render);

    this.section.addEventListener('mouseenter', () => {
      this.following = true;
    });

    this.section.addEventListener('mouseleave', () => {
      this.following = false;
    });
  }

  events = () => {
    window.addEventListener('mousemove', this.track);
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
    window.removeEventListener('mousemove', this.track);
  }

  init() {
    this.events();

    const player = new Player();
    player.init();

    if (Sniffer.isDevice) return;
    this.create();
  }
}
