// highway
import Highway from '@dogstudio/highway';

// components
import Components from '../components/index';

// filter
import Filter from '../components/filter/filter';

// utils
import Buttons from '../utils/buttons';
import Effects from '../utils/effects';
import Zoom from '../utils/zoom';

class Renderer extends Highway.Renderer {
  onEnterCompleted() {
    this.buttons = new Buttons();
    this.buttons.init();

    this.components = new Components();
    this.components.init();

    this.effects = new Effects();
    if (document.body.classList.contains('is-loading')) {
      this.effects.preload();
    } else {
      this.effects.init();
    }

    this.filter = new Filter();
    this.filter.init();

    this.zoom = new Zoom();
    this.zoom.init();
  }

  onLeaveCompleted() {
    this.buttons.destroy();
    this.buttons = undefined;

    this.components.destroy();
    this.components = undefined;

    this.effects.destroy();
    this.effects = undefined;

    this.filter.destroy();
    this.filter = undefined;

    this.zoom.destroy();
    this.zoom = undefined;
  }
}

// export
export default Renderer;
