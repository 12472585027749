import gsap from 'gsap';
import config from '../../utils/config';

export default class Image {
  constructor() {
    this.page = document.querySelector('.a-page');
    this.sections = document.querySelectorAll('.s-image');
    this.svgs = document.querySelectorAll('.s-image-svg');
    this.badges = document.querySelectorAll('.s-image-badge');
    this.fluids = document.querySelectorAll('.s-image-container.t-fluid img');

    this.delta = 0;
    this.rotate = 0;
    this.rotation = 0;
  }

  set = (i) => {
    this.font = {
      h2: i.querySelector('h2'),
    }

    if (this.font.h2) {
      this.font.h2.classList.add('f-hl');
    }
  }

  clear = () => {
    this.lp = null;
    this.delta = 0;
  }

  velocity = () => {
    this.py = Math.abs(this.page.getBoundingClientRect().top);

    this.delay = 50;

    if (this.lp != null) {
      this.delta = this.py - this.lp;
    }

    this.lp = this.py;

    clearTimeout(this.timer);

    this.timer = setTimeout(this.clear, this.delay);

    if (this.rotate > 360) { this.rotate = 0; }

    this.rotate += 1 / 5;
  }

  transform = () => {
    if (this.rotation < -360) { this.rotation = 0; };
    if (this.rotation > 360) { this.rotation = 0; };

    this.rotation = this.rotation + this.delta / 5;

    // badges
    [...this.badges].forEach((i) => {
      i.style.transform = `rotate(${this.rotate}deg)`;
    });

    [...this.svgs].forEach((i) => {
      i.style.transform = `rotate(${this.rotation}deg)`;
    });

    // fluids
    [...this.fluids].forEach((i) => {
      const b = i.getBoundingClientRect();

      const d = (config.wh + this.py) - (b.top + this.py);
      const p = d / ((config.wh + b.height) / 100);
      const s = 1.2 - ((p / 5) / 100);

      if (p > 0 && p < 100) {
        i.style.transform = `matrix(${s}, 0, 0, ${s}, 0, 0)`;
      }
    });
  }

  render = () => {
    this.velocity();
    this.transform();
  }

  resize = () => {
    config.wh = window.innerHeight;
  }

  create = () => {
    gsap.ticker.add(this.render);
    window.addEventListener('resize', this.resize, { passive: true });
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
    window.removeEventListener('resize', this.resize);
  }

  init() {
    [...this.sections].forEach((i) => {
      this.set(i);
    });

    if (this.badges.length > 0) {
      this.create();
    }
  }
}
