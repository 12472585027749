import gsap from 'gsap';
import Sniffer from 'sniffer';

export default class Panel {
  constructor() {
    this.section = document.getElementById('panel');
    this.background = document.querySelector('.p-background');
    this.cursor = document.querySelector('#cursor div');
    this.button = this.section.querySelector('.f-bs');
  }

  enter = () => {
    this.cursor.setAttribute('class', 'c-close');
    this.cursor.setAttribute('style', '');

    gsap.to(this.cursor, {
      opacity: 1,
    });
  }

  leave = () => {
    this.cursor.setAttribute('class', '');
    gsap.to(this.cursor, {
      opacity: 0,
    });
  }

  load = () => {
    document.querySelector('.f-bh').click();
  }

  events = () => {
    this.background.addEventListener('click', this.leave);
    this.button.addEventListener('click', this.load);

    if (Sniffer.isDevice) return;

    this.background.addEventListener('mouseenter', this.enter);
    this.background.addEventListener('mouseleave', this.leave);
  }

  init() {
    this.events();
  }
}