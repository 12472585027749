import gsap from 'gsap';
import Sniffer from 'sniffer';
import Panel from '../panel/panel';

export default class HeaderProduct {
  constructor() {
    this.app = document.getElementById('app');

    this.header = document.querySelector('.s-header-product');
    this.section = document.querySelector('.s-product-gallery');
    this.button = document.querySelector('.s-header-info .f-bs');

    this.ww = window.innerWidth;

    this.request = new XMLHttpRequest();
  }

  reset = () => {
    this.panel.el.remove();
    this.panel = undefined;

    document.body.style.overflow = '';
  }

  load = () => {
    this.data = this.button.dataset.id;
    this.request.open('POST', '' + document.location.origin + '/wp-admin/admin-ajax.php', true);
    this.request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;');

    this.request.onload = () => {
      this.panel.innerHTML = this.request.response;

      this.panel = {
        el: document.getElementById('panel'),
        container: document.querySelector('.p-container'),
        background: document.querySelector('.p-background'),
        button: document.querySelector('.p-footer .f-bs'),
        close: document.querySelector('#panel .p-close'),
      }

      const panel = new Panel();
      panel.init();

      this.open();
    };

    this.request.send('action=panel&id=' + this.data + '');
  }

  trigger = () => {
    if (this.panel) return;

    this.panel = document.createElement('div');
    this.panel.setAttribute('id', 'panel');

    this.app.insertAdjacentElement('afterEnd', this.panel);

    this.load();
  }

  open = () => {
    this.panel.el.style.visibility = 'inherit';
    document.body.style.overflow = 'hidden';

    gsap.set(this.panel.container, { xPercent: -100, });
    gsap.to(this.panel.container, { xPercent: 0, duration: 1.5, ease: 'exo', });
    gsap.to(this.panel.background, { opacity: 1, duration: 1.5, });

    this.panel.close.addEventListener('click', this.close);
    this.panel.button.addEventListener('click', this.close);
    this.panel.background.addEventListener('click', this.close);
  }

  close = () => {
    gsap.to(this.panel.container, { duration: 1.5, xPercent: -100, ease: 'exo', });
    gsap.to(this.panel.background, { opacity: 0, duration: 1.5, onComplete: this.reset, });
  }

  render = () => {
    this.hb = this.header.getBoundingClientRect();
    this.sb = this.section.getBoundingClientRect();

    this.ot = this.sb.top - this.ww * 0.14;
    this.ob = this.sb.height - this.hb.height;
    this.oc = this.ot < -this.ob - this.ww * 0.05313;

    if (this.ot < 0 && this.oc === false) {
      this.header.style.transform = `translate3d(0, ${Math.abs(this.ot)}px, 0)`;
    }
  }

  events = () => {
    this.button.addEventListener('click', this.trigger);
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
  }

  create = () => {
    gsap.ticker.add(this.render);
  }

  init() {
    this.events();
    if (Sniffer.isPhone) return;
    this.create();
  }
}
