import gsap from 'gsap';
import Sniffer from 'sniffer';

export default class Nav {
  constructor() {
    this.app = document.getElementById('app');
    this.nav = document.getElementById('nav');

    this.menu = document.querySelector('.n-menu');
    this.logo = document.querySelector('.n-logo');
    this.burger = document.getElementById('burger');

    this.figure = document.querySelector('.n-figure');
    this.image = document.querySelectorAll('.n-image');
    this.gallery = document.querySelector('.n-gallery');
    this.video = document.querySelector('.n-figure video');
    this.images = document.querySelectorAll('.n-figure img');
    this.objects = document.querySelectorAll('.n-image > *');

    this.column = document.querySelectorAll('.n-menu-column');
    this.background = document.querySelector('.n-background');

    this.sub = document.querySelectorAll('.n-menu-sub li');
    this.main = document.querySelectorAll('.n-menu-main li');
    this.links = document.querySelectorAll('.n-menu-links li');
    this.buttons = document.querySelectorAll('.n-menu-main a');

    this.visible = false;
    this.animating = false;

    this.location = location.href;

    this.active = 0;

    if (Sniffer.isPhone) {
      this.figure.remove();
    }
  }

  set = () => {
    this.animating = false;

    if (!Sniffer.isPhone) {
      this.video.pause();
    }

    this.nav.style.display = 'none';
    this.menu.style.pointerEvents = 'none';

    this.ww = window.innerWidth;
    this.wh = window.innerHeight;

    this.objects[this.active].classList.add('is-active');

    const a = this.nav.querySelectorAll('a');

    [...a].forEach((i) => {
      if (i.href === location.href) {
        i.classList.add('is-active');
      }
    });

    if (!Sniffer.isPhone) {
      gsap.set(this.logo, { x: 0, });
      gsap.set(this.gallery, { x: 0, });
      gsap.set(this.column, { y: this.wh, });
      gsap.set(this.background, { opacity: 0, });
      gsap.set(this.image, { x: 0, y: this.wh / 2, });
      gsap.set(this.main, { opacity: 0, y: -this.ww * 0.0625, });
      gsap.set([this.links, this.sub], { opacity: 0, });
      gsap.set([this.logo, this.menu, this.figure], { y: -this.wh, });
    } else {
      gsap.set(this.column, { x: -this.ww, });
      gsap.set([this.logo, this.menu], { x: this.ww, });
      gsap.set(this.main, { opacity: 0, x: this.ww * 0.0625, });
      gsap.set([this.links, this.sub], { opacity: 0, });
    }
  }

  toggle = () => {
    if (this.animating === true) return;
    this.animating = true;

    if (this.visible === false) {
      this.show();
      this.visible = true;
    } else {
      this.hide();
      this.visible = false;
    }
  }

  show = () => {
    this.nav.style.display = 'flex';

    this.burger.classList.add('is-active');

    if (document.body.classList.contains('is-light')) {
      this.burger.classList.add('is-nav');
    }

    // desktop
    if (!Sniffer.isPhone) {
      this.video.play();

      const tl = gsap.timeline({
        defaults: { duration: 1, ease: 'power3.inOut', clearProps: 'transform', },
        onComplete: () => {
          this.animating = false;
          this.menu.style.pointerEvents = '';
        }
      });
      tl
        .to(this.background, { opacity: 0.8, }, 0)
        .to([this.image, this.figure], { y: 0, }, 0)
        .to(this.sub, { opacity: 1, stagger: 0.05, }, 0.5)
        .to(this.links, { opacity: 1, stagger: 0.05, }, 0.5)
        .to([this.logo, this.menu, this.column], { y: 0, }, 0.1)
        .to(this.main, { y: 0, opacity: 1, stagger: -0.05, ease: 'power3.out' }, 0.5);

      // mobile
    } else {
      this.nav.scrollTo(0, 0);

      const tl = gsap.timeline({
        defaults: { duration: 1, ease: 'power3.inOut', clearProps: 'transform', },
        onComplete: () => {
          this.animating = false;
          this.menu.style.pointerEvents = '';
        }
      });

      tl
        .to(this.background, { opacity: 0.8, }, 0)
        .to(this.links, { opacity: 1, duration: 0.5 }, 0.5)
        .to([this.logo, this.menu, this.column], { x: 0, }, 0)
        .to(this.app, { x: -this.ww / 2, clearProps: 'none', }, 0.1)
        .to(this.sub, { opacity: 1, stagger: 0.05, duration: 0.5 }, 0.5)
        .to(this.main, { x: 0, opacity: 1, stagger: -0.05, duration: 0.5, ease: 'power3.out' }, 0.5);
    }
  }

  hide = () => {
    this.burger.classList.remove('is-active', 'is-nav');

    const tl = gsap.timeline({
      defaults: {
        duration: 1, ease: 'power3.inOut',
      },
      onComplete: () => {
        this.set();
      }
    });

    this.visible = false;

    // desktop
    if (!Sniffer.isPhone) {
      tl
        .to(this.figure, { y: -this.wh, }, 0)
        .to(this.image, { y: this.wh / 2, }, 0)
        .to(this.column, { y: this.wh, }, 0.05)
        .to([this.logo, this.menu], { y: -this.wh, }, 0.05)
        .to([this.main, this.sub, this.links, this.background], { opacity: 0 }, 0);

      // mobile
    } else {
      tl
        .to(this.column, { x: -this.ww, }, 0.05)
        .to([this.logo, this.menu], { x: this.ww, }, 0.05)
        .to(this.app, { x: 0, clearProps: 'transform', }, 0)
        .to([this.main, this.sub, this.links, this.background], { opacity: 0, duration: 0.5 }, 0);
    }
  }

  loading = () => {
    if (document.body.classList.contains('is-loader')) {
      return;
    };
    gsap.ticker.remove(this.loading);
    this.loader();
  }

  loader = () => {
    this.visible = false;

    this.burger.classList.remove('is-active', 'is-nav');

    const tl = gsap.timeline({
      defaults: {
        duration: 1, ease: 'power3.inOut',
      },
      onComplete: this.set,
    });

    const lb = this.logo.getBoundingClientRect();
    const gb = this.gallery.getBoundingClientRect();

    tl
      .set(this.nav, { clip: `rect(0px ${this.ww}px ${this.wh}px 0px)` })

      .to(this.gallery, { x: -gb.width, }, 0)
      .to(this.image, { x: gb.width / 2, }, 0)
      .to([this.main, this.links, this.sub], { opacity: 0, }, 0)
      .to(this.logo, { x: -(lb.left - window.innerWidth / 20 * 1), }, 0)
      .to(this.nav, { clip: `rect(${this.wh}px ${this.ww}px ${this.wh}px 0px)`, clearProps: 'all' }, 1);
  }

  resize = () => {
    this.ww = window.innerWidth;
    this.wh = window.innerHeight;

    if (this.visible === false) this.set();
  }

  enter = (i) => {
    let current = document.querySelector('.n-image .is-active');

    if (i === this.active) return;

    this.video.pause();

    [...this.objects].forEach((e) => {
      e.style.zIndex = 1;
    });

    current.style.zIndex = 2;

    this.active = i;

    current.classList.remove('is-active');

    if (this.active === 2) {
      this.video.play();
    }

    gsap.fromTo(this.objects[this.active],
      { zIndex: 3, scale: 1.1, opacity: 0, },
      { opacity: 1, scale: 1, ease: 'power3.out', duration: 1.5 }
    );

    this.objects[this.active].classList.add('is-active');
  }

  leave = () => {
    [...this.main].forEach((e, i) => {
      const active = e.querySelector('.is-active');
      if (active) {
        this.active = i;
      }
    });
  }

  events = () => {
    this.sub[0].querySelector('a').classList.add('is-sub');

    window.addEventListener('popstate', this.hide);
    window.addEventListener('resize', this.resize, { passive: true });

    this.burger.addEventListener('click', this.toggle);

    [...this.buttons].forEach((e) => {
      e.setAttribute('data-transition', 'navigation');
    });

    [...this.sub].forEach((e) => {
      e.querySelector('a').addEventListener('click', this.toggle);
      e.querySelector('a').setAttribute('data-transition', 'navigation');
    });

    [...this.links].forEach((e) => {
      const a = e.querySelector('a');
      if (a) { a.addEventListener('click', this.toggle); }
    });

    if (Sniffer.isPhone) {
      [...this.buttons].forEach((e) => {
        e.addEventListener('click', () => {
          this.toggle();
        });
      });
      return;
    }

    [...this.buttons].forEach((e, i) => {
      e.addEventListener('click', () => {
        document.body.classList.add('is-loader');
        gsap.ticker.add(this.loading);
      });
      e.addEventListener('mouseenter', () => {
        window.clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.enter(i);
        }, 250);
      });
      e.addEventListener('mouseleave', () => {
        window.clearTimeout(this.timeout);
      });
    });
  }

  init() {
    this.set();
    this.events();
  }
}
