import gsap from 'gsap';
import Sniffer from 'sniffer';

export default class Footer {
  constructor() {
    this.page = document.querySelector('.a-page');
    this.footer = document.querySelector('.s-footer');

    this.badge = this.footer.querySelector('.s-badge-outline');
    this.image = this.footer.querySelector('.s-badge-outline img');
    this.links = this.footer.querySelectorAll('.s-footer-column a');
    this.container = this.footer.querySelector('.s-footer-container');

    this.wh = window.innerHeight;

    this.delta = 0;
    this.rotate = 0;
    this.rotation = 0;
  }

  set = () => {
    for (let i = 0; i < this.links.length; i++) {
      const link = this.links[i];

      link.classList.remove('is-active');

      if (link.href === location.href) {
        link.classList.add('is-active');
      }
    }
  }

  velocity = () => {
    this.py = Math.abs(this.page.getBoundingClientRect().top);

    this.delay = 50;

    if (this.lp != null) {
      this.delta = this.py - this.lp;
    }

    this.lp = this.py;

    clearTimeout(this.timer);

    this.timer = setTimeout(this.clear, this.delay);

    if (this.rotate > 360) { this.rotate = 0; }

    this.rotate += 1 / 5;
  }

  transform = () => {
    if (this.rotation < -360) { this.rotation = 0; };
    if (this.rotation > 360) { this.rotation = 0; };

    this.rotation = this.rotation + this.delta / 5;

    this.badge.style.transform = `rotate(${this.rotate}deg)`;
    this.image.style.transform = `rotate(${this.rotation}deg)`;
  }

  inview = () => {
    const b = this.footer.getBoundingClientRect();
    const y = Math.abs(this.page.getBoundingClientRect().top);

    const c = (this.wh + y) - (b.top + y);
    const d = -b.height / 2;

    if (c > 0) {
      this.velocity();
      this.transform();

      if (Sniffer.isPhone) return;

      this.container.style.transform = `translate3d(0, ${d + (c / 2)}px, 0)`;
    }
  }

  render = () => {
    this.inview();
  }

  resize = () => {
    this.wh = window.innerHeight;
  }

  create = () => {
    gsap.ticker.add(this.render);
    window.addEventListener('resize', this.resize, { passive: true });
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
    window.removeEventListener('resize', this.resize);
  }

  init() {
    this.set();
    this.create();
  }
}
