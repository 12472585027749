import gsap from 'gsap';

export default class Menu {
  constructor() {
    this.section = document.getElementById('menu');

    this.menu = {
      el: this.section.querySelector('.m-menu'),
      logo: this.section.querySelector('.m-logo'),
      top: this.section.getBoundingClientRect().top,
      bottom: this.section.getBoundingClientRect().bottom,
      buttons: this.section.querySelectorAll('.m-menu li'),
    }

    this.scroll = document.querySelector('.a-page');
    this.header = document.querySelector('.s-header-video');

    this.hidden = false;
  }

  show = () => {
    this.menu.el.style.pointerEvents = '';
    gsap.to(this.menu.logo, { opacity: 1, pointerEvents: '', duration: 0.25, });
    gsap.to(this.menu.buttons, { opacity: 1, duration: 0.25, stagger: 0.05, });
  }

  hide = () => {
    this.menu.el.style.pointerEvents = 'none';
    gsap.to(this.menu.logo, { opacity: 0, pointerEvents: 'none', duration: 0.25, });
    gsap.to(this.menu.buttons, { opacity: 0, duration: 0.25, stagger: 0.05, });
  }

  visibility = () => {
    this.scrollY = this.scroll.getBoundingClientRect().top;

    if (this.scrollY < -25) {
      if (this.hidden === true) return;
      this.hidden = true;
      this.hide();
    } else {
      if (this.hidden === false) return;
      this.hidden = false;
      this.show();
    }
  }

  contrast = () => {
    if (this.menu.bottom < this.header.getBoundingClientRect().top + this.header.clientHeight) {
      if (this.dp === true) return;
      this.dp = true;
      document.body.classList.add('is-light');
    } else {
      if (this.dp === false) return;
      this.dp = false;
      document.body.classList.remove('is-light');
    }
  }

  render = () => {
    if (this.header) {
      this.contrast();
    } else {
      this.dp = false;
      document.body.classList.remove('is-light');
    }
    this.visibility();
  }

  create = () => {
    gsap.ticker.add(this.render);
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
  }

  init() {
    this.create();
    this.show();
  }
}
